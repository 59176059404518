<template>
  <div class="Manufacturer">
    <div class="formWrap">
      <span class="formName">厂商名称</span>
      <el-input
        class="formBody"
        v-model="manufacturerName"
        clearable
        placeholder="请输入内容"
      ></el-input>
      <el-button class="mr10" type="primary" @click="onList()">查询</el-button>
      <el-button @click="onReList()">重置</el-button>
    </div>

    <el-button type="primary" icon="el-icon-plus" @click="handleAdd()"
      >添加</el-button
    >
    <el-table :data="listData" class="list">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column
        label="厂商名称"
        prop="manufacturerName"
      ></el-table-column>
      <!-- <el-table-column prop="content" label="字段内容" show-overflow-tooltip></el-table-column> -->
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        label="修改时间"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="onList"
      :current-page.sync="pageNum"
      :page-size="10"
      layout="total, prev, pager, next"
      :total="totals"
    >
    </el-pagination>

    <el-dialog title="厂商信息" :visible.sync="dialogFormVisible">
      <el-form label-position="left" :model="form">
        <el-form-item label="厂商名称" :label-width="formLabelWidth">
          <el-input v-model="form.manufacturerName" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="字段内容" :label-width="formLabelWidth">
          <el-input v-model="form.content" type="textarea" :autosize="{ minRows: 5, maxRows: 20}" autocomplete="off"></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
  <style lang="less" scoped>
.Manufacturer {
  padding: 20px;
}
</style>
  
  <script>
import { timeout } from 'q';

export default {
  name: "Manufacturer",
  components: {},
  data() {
    return {
      manufacturerName: "",
      listData: [],
      pageNum: 1,
      pageSize: 10,
      totals: 0,
      dialogFormVisible: false,
      form: {
        id: '',
        manufacturerName: '',
        // content: '',
      },
      formLabelWidth: '80px'
    };
  },
  computed: {},
  mounted() {
    this.onList();
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },

    // 获取列表数据
    onList: function () {
      var that = this;
      var data = {
        manufacturerName: this.manufacturerName,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/manufacturer/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.listData = res.data.list;
          that.totals = res.data.totals;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 重置列表
    onReList: function () {
      this.manufacturerName = "";
      this.pageNum = 1;
      this.totals = 0;
      this.onList();
    },
    // 添加
    handleAdd: function () {
      this.form.id = ''
      this.form.manufacturerName = ''
      // this.form.content = ''
      this.dialogFormVisible = true
    },
    // 编辑
    handleEdit: function (row) {
      this.form.id = row.id
      this.form.manufacturerName = row.manufacturerName
      // this.form.content = JSON.stringify(JSON.parse(row.content), null, '  ')
      this.dialogFormVisible = true
    },
    // 取消
    handleClose: function () {
      this.form.id = ''
      this.form.manufacturerName = ''
      // this.form.content = ''
      this.dialogFormVisible = false
    },
    // 确定
    handleConfirm: function () {
      this.$confirm("此操作将保存数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onConfirm();
        })
        .catch((err) => {
          setTimeout(() => {
            this.$message({
              type: "info",
              message: 'JSON解析异常或数据提交失败',
            });
          }, 100);
          this.$message({
            type: "info",
            message: err,
          });
        });
    },
    // 提交数据
    onConfirm: function () {
      var that = this;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/manufacturer/submit",
          data: JSON.stringify(that.form),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功",
            });
          } else {
            that.$message({
              type: "info",
              message: res.msg,
            });
          }
          that.handleClose()
          that.onList()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除
    handleDelete(row) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDelete(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行删除
    onDelete: function (id) {
      var that = this;
      var data = {
        id: id,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/manufacturer/remove",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功",
            });
          } else {
            that.$message({
              type: "info",
              message: res.msg,
            });
          }
          that.onList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
  